import { playSound } from 'utils/media';

export const playSuccessSound = () => {
  playSound('success.mp3');
};

export const playFailSound = () => {
  playSound('error.mp3');
};

export const playSpecialNoteSound = () => {
  playSound('special-note.mp3');
};
