import { AxiosError } from 'axios';
import { ServiceRoute } from 'constants/api';

export function hasCode(error: unknown): error is { code: number } {
  return error ? typeof error === 'object' && 'code' in error : false;
}

export function hasserviceRoute(error: unknown): error is { serviceRoute: ServiceRoute; error: unknown } {
  return error
    ? typeof error === 'object' &&
        'serviceRoute' in error &&
        'error' in error &&
        typeof error.serviceRoute === 'string' &&
        isserviceRoute(error.serviceRoute)
    : false;
}

function isserviceRoute(value: string): value is ServiceRoute {
  return Object.values(ServiceRoute).includes(value as ServiceRoute);
}

export function getErrorFromAxiosError(error: unknown) {
  if (hasserviceRoute(error) && error.error instanceof AxiosError && error.error.response) {
    return error.error.response?.data as {
      code: number;
      message: string;
    };
  }
}
