import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

// assets
import error404 from 'assets/images/maintenance/Error404.png';
import TwoCone from 'assets/images/maintenance/TwoCone.png';
import { ROUTE_URL } from 'constants/route';
import ErrorRender from 'components/ErrorRender';
import { withErrorBoundary } from 'react-error-boundary';

// ==============================|| ERROR 404 - MAIN ||============================== //

function Error404() {
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', pt: 1.5, pb: 1, overflow: 'hidden' }}
      >
        <Grid item xs={12}>
          <Stack direction="row">
            <Grid item>
              <Box
                sx={{
                  width: { xs: 250, sm: 590 },
                  height: { xs: 130, sm: 300 }
                }}
              >
                <img src={error404} alt="mantis" style={{ width: '100%', height: '100%' }} />
              </Box>
            </Grid>
            <Grid item sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 60,
                  left: -40,
                  width: { xs: 130, sm: 390 },
                  height: { xs: 115, sm: 330 }
                }}
              >
                <img src={TwoCone} alt="mantis" style={{ width: '100%', height: '100%' }} />
              </Box>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography variant="h1">Page Not Found</Typography>
            <Typography color="textSecondary" align="center" sx={{ width: { xs: '73%', sm: '61%' } }}>
              The page you are looking was moved, removed, renamed, or might never exist!
            </Typography>
            <Stack direction={'row'} justifyContent={'center'} gap={1} sx={{ textTransform: 'none', mt: 4 }}>
              <Button variant="outlined" onClick={() => navigate(-1)}>
                Back
              </Button>
              <Button component={Link} to={ROUTE_URL.home} variant="contained">
                Home
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default withErrorBoundary(Error404, {
  FallbackComponent: ErrorRender
});
