import { authActions } from 'modules/auth/slice';
import { useDispatch } from 'store';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(authActions.logout());
  };

  const updateAvatar = (avatar: string) => {
    dispatch(authActions.changeAvatar(avatar));
  };

  return { logout, updateAvatar };
};
