import MainLayout from 'layout/MainLayout';
import { Navigate } from 'react-router';
import { lazy } from 'react';
import AuthGuard from 'modules/auth/route-guard/AuthGuard';
import Loadable from 'components/Loadable';
import ExportOrderList from 'modules/delivery/components/export-order/ExportOrderList';

const TiktokAuthList = Loadable(lazy(() => import('modules/tiktok-auth/components/List')));
const CrawlManagement = Loadable(lazy(() => import('modules/crawl-data/components/CrawlManagement')));
const ProfitMarginRateReport = Loadable(lazy(() => import('modules/mkt-report/components/profit-margin-rate/Report')));
const RevenueByChannelReport = Loadable(lazy(() => import('modules/mkt-report/components/revenue-by-channel/Report')));

const DeliveryDetailPage = Loadable(lazy(() => import('modules/delivery/components/detail/DeliveryDetailPage')));
const InventoryListPage = Loadable(lazy(() => import('modules/inventory/components/list/ListPage')));
const InventoryDetailPage = Loadable(lazy(() => import('modules/inventory/components/detail/InventoryDetailPage')));

const ProfilePage = Loadable(lazy(() => import('pages/account/profile')));
const ChangePasswordPage = Loadable(lazy(() => import('pages/account/change-password')));

const EmployeesListPage = Loadable(lazy(() => import('pages/employees/list')));
const AddEmployeePage = Loadable(lazy(() => import('pages/employees/create')));
const EmployeeDetailPage = Loadable(lazy(() => import('pages/employees/detail')));

const RoleListPage = Loadable(lazy(() => import('pages/role/list')));
const CreateRolePage = Loadable(lazy(() => import('pages/role/create')));
const RoleDetailPage = Loadable(lazy(() => import('pages/role/detail')));

const LocationListPage = Loadable(lazy(() => import('pages/location/list')));
const DepartmentListPage = Loadable(lazy(() => import('pages/department/list')));
const PositionListPage = Loadable(lazy(() => import('pages/position/list')));
const ResourceListPage = Loadable(lazy(() => import('pages/resource/list')));

const ServiceListPage = Loadable(lazy(() => import('modules/service/component/ServiceListPage')));

const TimekeepingTicketListPage = Loadable(lazy(() => import('modules/timekeeping/components/TimekeepingTicketsList')));
const TimekeepingTicketDetailPage = Loadable(lazy(() => import('modules/timekeeping/components/TimekeepingTicketDetailPage')));
const OnLeaveListPage = Loadable(lazy(() => import('modules/on-leave/components/OnLeaveListPage')));
const OnLeaveTicketDetailPage = Loadable(lazy(() => import('modules/on-leave/components/OnLeaveTicketDetailPage')));
const ViolationListPage = Loadable(lazy(() => import('modules/violation/components/ViolationListPage')));
const ViolationDetailPage = Loadable(lazy(() => import('modules/violation/components/ViolationDetailPage')));
const CreateViolation = Loadable(lazy(() => import('modules/violation/components/CreateViolation')));
const TimekeepingHistoryList = Loadable(lazy(() => import('modules/timekeeping/components/TimekeepingHistoryList')));
const SystemTimekeepingTicketsList = Loadable(lazy(() => import('modules/timekeeping/components/SystemTimekeepingTicketsList')));
const SystemTimekeepingTicketDetailPage = Loadable(lazy(() => import('modules/timekeeping/components/SystemTimekeepingTicketDetailPage')));
const Report = Loadable(lazy(() => import('modules/timekeeping/components/Report')));
const ViolationReport = Loadable(lazy(() => import('modules/violation/components/ViolationReport')));
const ViolationPointDetailPage = Loadable(lazy(() => import('modules/violation/components/ViolationPointDetailPage')));

// const ProductLabel = Loadable(lazy(() => import('modules/sale/components/ProductLabelList')));
const OrderNoteList = Loadable(lazy(() => import('modules/order-note/components/List')));
const DeliveryList = Loadable(lazy(() => import('modules/delivery/components/list/DeliveryList')));

const InStoreSalesReport = Loadable(lazy(() => import('modules/sale/components/in-store-report/InStoreReport')));
const EmployeeRevenueReport = Loadable(lazy(() => import('modules/sale/components/EmployeeRevenue')));
const ComplaintList = Loadable(lazy(() => import('modules/complaint/components/List')));
const ComplaintDetail = Loadable(lazy(() => import('modules/complaint/components/Detail')));
const EcommerceOrders = Loadable(lazy(() => import('modules/e-commerce-gift/components/EcommerceOrderList')));

const ZaloVoucherList = Loadable(lazy(() => import('modules/zalo-mini-app/components/promotions/PromotionList')));
const ZaloUIManagement = Loadable(lazy(() => import('modules/zalo-mini-app/components/ui-management/UIManagement')));
const ZaloPromotionTagManagement = Loadable(lazy(() => import('modules/zalo-mini-app/components/tag-management/List')));
const TagDetail = Loadable(lazy(() => import('modules/zalo-mini-app/components/tag-management/TagDetail')));
const NewsList = Loadable(lazy(() => import('modules/zalo-mini-app/components/news/NewsList')));
const AddNews = Loadable(lazy(() => import('modules/zalo-mini-app/components/news/AddNews')));
const NewsDetail = Loadable(lazy(() => import('modules/zalo-mini-app/components/news/NewsDetail')));

const OpenAppReport = Loadable(lazy(() => import('modules/zalo-mini-app/components/report/OpenAppReport')));
const PointReport = Loadable(lazy(() => import('modules/zalo-mini-app/components/report/PointStateReport')));
const UserReport = Loadable(lazy(() => import('modules/zalo-mini-app/components/report/UserReport')));
const VoucherReport = Loadable(lazy(() => import('modules/zalo-mini-app/components/report/VoucherReport')));
const AccessCountHistoryReport = Loadable(
  lazy(() => import('modules/zalo-mini-app/components/report/access-history-count/AccessHistoryCountReport'))
);
const RecentInteractReport = Loadable(lazy(() => import('modules/zalo-mini-app/components/report/RecentInteract')));

const ProductList = Loadable(lazy(() => import('modules/product-management/components/ProductList')));
const RetailReportByChanel = Loadable(lazy(() => import('modules/mkt-report/components/retail-report-by-chanel/Report')));
const LowSellingReport = Loadable(lazy(() => import('modules/mkt-report/components/low-selling/Report')));
const FBChatbotResponseManagement = Loadable(lazy(() => import('modules/fb-chatbot-response/components/FBChatbotResponseManagement')));
const BlogMenuGenerator = Loadable(lazy(() => import('modules/marketing-tools/components/BlogMenuGenerator')));

const ShopeeDebtReport = Loadable(lazy(() => import('modules/accounting-report/components/shopee-debt/Report')));
const GHNDebtReport = Loadable(lazy(() => import('modules/accounting-report/components/ghn-debt/Report')));
const TiktokDebtReport = Loadable(lazy(() => import('modules/accounting-report/components/tiktok-debt/Report')));
const SixMonthSellingReport = Loadable(lazy(() => import('modules/mkt-report/components/6-month-selling')));

const MainRoutes = {
  path: '',
  children: [
    {
      path: '',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="tai-khoan" />
        },
        {
          path: 'tai-khoan',
          children: [
            {
              index: true,
              element: <ProfilePage />
            },
            {
              path: 'doi-mat-khau',
              element: <ChangePasswordPage />
            }
          ]
        },
        {
          path: 'nhan-vien',
          children: [
            {
              index: true,
              element: <EmployeesListPage />
            },
            {
              path: ':id',
              element: <EmployeeDetailPage />
            },
            {
              path: 'tao-moi',
              element: <AddEmployeePage />
            }
          ]
        },
        {
          path: 'vai-tro',
          children: [
            {
              index: true,
              element: <RoleListPage />
            },
            {
              path: 'them-moi',
              element: <CreateRolePage />
            },
            {
              path: ':id',
              element: <RoleDetailPage />
            }
          ]
        },
        {
          path: 'chi-nhanh',
          children: [
            {
              index: true,
              element: <LocationListPage />
            }
          ]
        },
        {
          path: 'phong-ban',
          children: [
            {
              index: true,
              element: <DepartmentListPage />
            }
          ]
        },
        {
          path: 'chuc-vu',
          children: [
            {
              index: true,
              element: <PositionListPage />
            }
          ]
        },
        {
          path: 'resource',
          children: [
            {
              index: true,
              element: <ResourceListPage />
            }
          ]
        },
        {
          path: 'phieu-cham-cong',
          children: [
            {
              index: true,
              element: <TimekeepingTicketListPage />
            },
            {
              path: ':id',
              element: <TimekeepingTicketDetailPage />
            }
          ]
        },
        {
          path: 'lich-su-cham-cong',
          children: [
            {
              index: true,
              element: <TimekeepingHistoryList />
            }
          ]
        },
        {
          path: 'phieu-nghi-phep',
          children: [
            {
              index: true,
              element: <OnLeaveListPage />
            },
            {
              path: ':id',
              element: <OnLeaveTicketDetailPage />
            }
          ]
        },
        {
          path: 'cham-cong-he-thong',
          children: [
            {
              index: true,
              element: <SystemTimekeepingTicketsList />
            },
            {
              path: ':id',
              element: <SystemTimekeepingTicketDetailPage />
            }
          ]
        },
        {
          path: 'phieu-sai-pham',
          children: [
            {
              index: true,
              element: <ViolationListPage />
            },
            {
              path: 'tao-sai-pham',
              element: <CreateViolation />
            },
            {
              path: 'diem-sai-pham',
              children: [
                {
                  index: true,
                  element: <ViolationReport />
                },
                {
                  path: ':id',
                  element: <ViolationPointDetailPage />
                }
              ]
            },
            {
              path: ':id',
              element: <ViolationDetailPage />
            }
          ]
        },
        {
          path: 'bao-cao-cham-cong',
          children: [
            {
              index: true,
              element: <Report />
            }
          ]
        },
        // {
        //   path: 'nhan-san-pham',
        //   element: <ProductLabel />
        // },
        {
          path: 'bao-cao-kinh-doanh',
          children: [
            {
              index: true,
              element: <Navigate to={'kinh-doanh-tai-cua-hang'} />
            },
            {
              path: 'kinh-doanh-tai-cua-hang',
              element: <InStoreSalesReport />
            },
            {
              path: 'doanh-so-nhan-vien',
              element: <EmployeeRevenueReport />
            }
          ]
        },
        {
          path: 'ghi-chu-don-hang',
          element: <OrderNoteList />
        },
        {
          path: 'khieu-nai',
          children: [
            {
              index: true,

              element: <ComplaintList />
            },
            {
              path: ':id',
              element: <ComplaintDetail />
            }
          ]
        },
        {
          path: 'zalo-mini-app',
          children: [
            {
              index: true,
              element: <Navigate to={'promotion'} />
            },
            {
              path: 'promotion',
              element: <ZaloVoucherList />
            },
            {
              path: 'quan-ly-ui',
              element: <ZaloUIManagement />
            },
            {
              path: 'quan-ly-tag',
              children: [
                { index: true, element: <ZaloPromotionTagManagement /> },
                {
                  path: ':id',
                  element: <TagDetail />
                }
              ]
            },
            {
              path: 'tin-tuc',
              children: [
                {
                  index: true,
                  element: <NewsList />
                },
                {
                  path: 'them',
                  element: <AddNews />
                },
                {
                  path: ':id',
                  element: <NewsDetail />
                }
              ]
            },
            {
              path: 'bao-cao',
              children: [
                {
                  path: 'so-lan-mo-app',
                  element: <OpenAppReport />
                },
                {
                  path: 'phat-sinh-diem',
                  element: <PointReport />
                },
                {
                  path: 'nguoi-dung',
                  element: <UserReport />
                },
                {
                  path: 'qua-da-doi',
                  element: <VoucherReport />
                },
                {
                  path: 'luot-truy-cap',
                  element: <AccessCountHistoryReport />
                },
                {
                  path: 'tuong-tac-gan-day',
                  element: <RecentInteractReport />
                }
              ]
            }
          ]
        },
        {
          path: 'marketing',
          children: [
            {
              index: true,
              element: <Navigate to={'quan-ly-san-pham'} />
            },
            {
              path: 'quan-ly-san-pham',
              element: <ProductList />
            },
            {
              path: 'bao-cao',
              children: [
                {
                  index: true,
                  element: <Navigate to={'ban-le-theo-kenh'} />
                },
                {
                  path: 'ban-le-theo-kenh',
                  element: <RetailReportByChanel />
                },
                {
                  path: 'san-pham-ban-khong-chay',
                  element: <LowSellingReport />
                },
                {
                  path: 'tinh-hinh-ban-hang',
                  element: <SixMonthSellingReport />
                },
                {
                  path: 'ty-suat-loi-nhuan-theo-san-pham',
                  element: <ProfitMarginRateReport />
                },
                {
                  path: 'doanh-thu-theo-kenh',
                  element: <RevenueByChannelReport />
                }
              ]
            },
            {
              path: 'quan-ly-chatbot-fb',
              element: <FBChatbotResponseManagement />
            },
            {
              path: 'menu-generator',
              element: <BlogMenuGenerator />
            }
          ]
        },
        {
          path: 'ke-toan',
          children: [
            {
              path: 'bao-cao',
              children: [
                {
                  path: 'cong-no-shopee',
                  element: <ShopeeDebtReport />
                },
                {
                  path: 'cong-no-ghn',
                  element: <GHNDebtReport />
                },
                {
                  path: 'cong-no-tiktok',
                  element: <TiktokDebtReport />
                }
              ]
            }
          ]
        },
        {
          path: 'qua-tang-tmdt',
          element: <EcommerceOrders />
        },
        {
          path: 'service',
          children: [
            {
              index: true,
              element: <ServiceListPage />
            }
          ]
        },
        {
          path: 'kho-hang',
          children: [
            {
              index: true,
              element: <Navigate to={'giao-hang'} />
            },
            {
              path: 'giao-hang',
              children: [
                {
                  index: true,
                  element: <DeliveryList />
                },
                {
                  path: ':id',
                  element: <DeliveryDetailPage />
                }
              ]
            },
            {
              path: 'xuat-kho',
              element: <ExportOrderList />
            },
            {
              path: 'kiem-ke',
              children: [
                {
                  index: true,
                  element: <InventoryListPage />
                },
                {
                  path: ':id',
                  element: <InventoryDetailPage />
                }
              ]
            }
          ]
        },
        {
          path: 'tiktok-auth',
          element: <TiktokAuthList />
        },
        {
          path: 'crawl-data',
          element: <CrawlManagement />
        }
      ]
    }
  ]
};

export default MainRoutes;
