import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { ROUTE_URL } from 'constants/route';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: string;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => (
  <ButtonBase disableRipple component={Link} to={!to ? ROUTE_URL.home : to} sx={sx}>
    <img src="/assets/img/logo.png" height={'100%'} alt="" />
    {isIcon ? (
      ''
    ) : (
      <Typography
        sx={{
          color: '#1b5af9',
          fontSize: 16,
          fontWeight: 900,
          textTransform: 'uppercase'
        }}
      >
        THEGIOIWHEY
      </Typography>
    )}
  </ButtonBase>
);

export default LogoSection;
