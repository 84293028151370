import { Button, Stack, Typography } from '@mui/material';
import ErrorRender from 'components/ErrorRender';
import MainCard from 'components/MainCard';
import Modal, { IModalRef } from 'components/Modal';
import { Context } from 'modules/delivery/DetailContext';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

export interface ScannerRef {
  waitingForScanning(): void;
}

interface Props {
  onScanSuccess(code: string): void;
  disable?: boolean;
}

const Scanner = forwardRef<ScannerRef, Props>(({ onScanSuccess, disable }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // const confirmNoteRef = useRef<IModalRef>(null);
  // const { data, checkOrderNote, addOrderToBatch } = useContext(Context);
  const [isScanning, setIsScanning] = useState(false);
  const [barcode, setBarcode] = useState('');

  const startScanning = () => {
    setIsScanning(true);
  };

  const stopScanning = () => setIsScanning(false);

  // const startAdding = () => {
  //   addOrderToBatch(
  //     {
  //       batchId: data?.id!,
  //       barcode
  //     },
  //     {
  //       onSuccess() {
  //         endAdding();
  //       },
  //       onError() {
  //         endAdding();
  //       }
  //     }
  //   );
  // };

  // const endAdding = () => {
  //   confirmNoteRef.current?.close();
  //   resetInfo();
  //   inputRef.current?.focus();
  // };

  useEffect(() => {
    if (isScanning) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [isScanning, inputRef.current]);

  // const resetInfo = () => {
  //   setBarcode('');
  //   setSpecialNote('');
  // };

  useImperativeHandle(ref, () => ({
    waitingForScanning() {
      setTimeout(() => {
        startScanning();
        inputRef.current?.focus();
        setBarcode('');
      }, 100);
    }
  }));

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'F8') {
        e.preventDefault();
        startScanning();
        inputRef.current?.focus();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  console.log('barcode', barcode);

  return (
    <>
      {/* <MainCard
        title={'Quét mã vận đơn'}
        sx={{
          height: '100%'
        }}
      > */}
      <Stack direction={'column'} alignItems={'center'}>
        {isScanning ? (
          <Button color="error" variant="contained" onClick={stopScanning}>
            Ngưng quét
          </Button>
        ) : (
          <Button variant="contained" onClick={startScanning}>
            Bắt đầu Quét (F8)
          </Button>
        )}
        <Stack direction={'column'} height={160} alignItems={'center'}>
          {isScanning && (
            <>
              <Stack flexGrow={1} overflow={'hidden'} justifyContent={'center'} alignItems={'center'}>
                <img
                  src="/assets/img/scan.gif"
                  style={{
                    objectPosition: 'center'
                  }}
                  height={200}
                  alt=""
                />
              </Stack>
              <Typography variant="body2">Nhấn F8 để focus vào ô input bên dưới để quét nếu ô này chưa được focus vào</Typography>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  barcode && onScanSuccess(barcode);
                  // checkOrderNote(barcode, {
                  //   onSuccess({ note }, variables) {
                  //     if (note) {
                  //       setSpecialNote(note);
                  //       confirmNoteRef.current?.open();
                  //     } else {
                  //       startAdding();
                  //     }
                  //   },
                  //   onError() {
                  //     resetInfo();
                  //     inputRef.current?.focus();
                  //   }
                  // });
                }}
              >
                <input
                  type="text"
                  disabled={disable}
                  placeholder="Đang chờ quét..."
                  ref={inputRef}
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                />
              </form>
            </>
          )}
        </Stack>
      </Stack>
      {/* </MainCard> */}
    </>
  );
});

export default withErrorBoundary(Scanner, { FallbackComponent: ErrorRender });
