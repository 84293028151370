import { Button, Typography } from '@mui/material';
import Modal, { IModalRef } from 'components/Modal';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

export interface SpecialNoteRemindRef {
  open(note: string, onSubmit: () => void): void;
  close(): void;
}

const SpecialNoteRemindModal = forwardRef<SpecialNoteRemindRef>((_, ref) => {
  const modalRef = useRef<IModalRef>(null);

  const [onSubmit, setOnSubmit] = useState<() => void>(() => {});
  const [specialNote, setSpecialNote] = useState<string>('');

  useImperativeHandle(ref, () => ({
    open(note, onSubmit) {
      setOnSubmit(() => onSubmit);
      setSpecialNote(() => note);
      modalRef.current?.open();
    },
    close() {
      modalRef.current?.close();
    }
  }));

  return (
    <Modal
      ref={modalRef}
      title="Nhắc nhở"
      action={
        <Button
          variant="contained"
          onClick={() => {
            onSubmit();
          }}
        >
          Xác nhận và thêm đơn hàng
        </Button>
      }
      onClose={() => {
        setSpecialNote('');
        setOnSubmit(() => () => {});
      }}
      disableCloseOnClickOutside
    >
      <Typography>Đơn hàng này có ghi chú đặc biệt</Typography>
      <Typography>Nội dung ghi chú: {specialNote}</Typography>
    </Modal>
  );
});

export default SpecialNoteRemindModal;
