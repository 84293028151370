import { VariantType, enqueueSnackbar } from 'notistack';

const snackbar = (type: VariantType | undefined, msg: string) =>
  enqueueSnackbar({ variant: type, message: msg, anchorOrigin: { horizontal: 'center', vertical: 'top' } });

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  success: (msg: string) => snackbar('success', msg),
  error: (msg: string) => snackbar('error', msg),
  warning: (msg: string) => snackbar('warning', msg)
};
