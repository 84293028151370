import {
  ApartmentOutlined,
  BugOutlined,
  ClockCircleOutlined,
  CoffeeOutlined,
  ContainerOutlined,
  ExceptionOutlined,
  ShoppingOutlined,
  TableOutlined,
  UsergroupAddOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { ROUTE_URL, TIMEKEEPING_ROUTES } from 'constants/route';
import { NavItemType } from 'types/menu';

const humanResourcesManagement: NavItemType = {
  id: 'hr',
  title: 'Quản lý nhân sự',
  type: 'collapse',
  icon: UsergroupAddOutlined,
  children: [
    {
      id: 'employee',
      title: 'Nhân viên',
      type: 'item',
      icon: UsergroupAddOutlined,
      url: ROUTE_URL.employee.list,
      permission: 'employee'
    },
    {
      id: 'report',
      title: 'Thống kê',
      type: 'item',
      icon: TableOutlined,
      url: TIMEKEEPING_ROUTES.report,
      permission: 'timekeeping-manage'
    },
    {
      id: 'timekeeping-history',
      title: 'Lịch sử chấm công',
      type: 'item',
      icon: ClockCircleOutlined,
      url: TIMEKEEPING_ROUTES.timekeepingHistory.list,
      permission: 'timekeeping-manage'
    },
    {
      id: 'timekeeping-ticket',
      title: 'Quên công và thêm ca',
      type: 'item',
      icon: ContainerOutlined,
      url: TIMEKEEPING_ROUTES.timekeepingTickets.list,
      permission: 'timekeeping-manage'
    },
    {
      id: 'on-leave',
      title: 'Phiếu nghỉ phép',
      type: 'item',
      icon: CoffeeOutlined,
      url: TIMEKEEPING_ROUTES.onLeave.list,
      permission: 'timekeeping-manage'
    },
    {
      id: 'violation',
      title: 'Phiếu sai phạm',
      type: 'item',
      icon: WarningOutlined,
      url: TIMEKEEPING_ROUTES.violation.list,
      permission: 'timekeeping-manage'
    },
    {
      id: 'violation-point',
      type: 'item',
      title: 'Điểm sai phạm',
      icon: ExceptionOutlined,
      url: TIMEKEEPING_ROUTES.violation.point.list,
      permission: 'timekeeping-manage'
    },
    {
      id: 'department',
      title: 'Phòng ban',
      type: 'item',
      icon: ApartmentOutlined,
      url: ROUTE_URL.department,
      permission: 'department'
    },
    {
      id: 'position',
      title: 'Chức vụ',
      type: 'item',
      icon: ShoppingOutlined,
      url: ROUTE_URL.position,
      permission: 'position'
    },
    {
      id: 'system-timekeeping',
      title: 'Chấm hệ thống',
      type: 'item',
      icon: BugOutlined,
      url: TIMEKEEPING_ROUTES.systemTimekeepingTickets.list,
      permission: 'system-timekeeping'
    }
  ]
};

export default humanResourcesManagement;
