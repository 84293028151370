import { EException } from 'types/response-code/auth';

export const authErrorMessageInfo: Record<EException, string> = {
  '-1': 'Vui lòng kiểm tra lại thông tin',
  '400': 'Tên đăng nhập hoặc mật khẩu sai',
  '404': 'Nhân viên không tồn tại hoặc đã ngưng làm việc',
  '405': 'Mật khẩu cũ sai',
  '406': 'Hình ảnh không hợp lệ',
  '407': 'Link reset password không hợp lệ hoặc đã quá hạn'
};
