import { wareHouseAxios } from 'constants/api';
import { DeliveryBatchDetail, DeliveryBatchItem, DeliveryBatchesResponse, EditBatchInfo } from 'modules/delivery/interface';
import queryString from 'query-string';

class DeliveryBatchApi {
  async list({ queryKey }: { queryKey: any }): Promise<DeliveryBatchesResponse> {
    return wareHouseAxios.instance.get('/delivery-checking?' + queryString.stringify(queryKey[1]));
  }

  async detail(id: number | undefined | string): Promise<{ batch: DeliveryBatchDetail }> {
    return wareHouseAxios.instance.get(`/delivery-checking/${id}`);
  }

  async updateInfo({
    id,
    ...data
  }: Pick<DeliveryBatchDetail, 'id'> & EditBatchInfo): Promise<Pick<DeliveryBatchDetail, 'id'> & EditBatchInfo> {
    return wareHouseAxios.instance.patch(`/delivery-checking/${id}`, data);
  }

  async confirmBatches({ ids }: { ids: number[] }): Promise<{ success_ids: number[] }> {
    return wareHouseAxios.instance.patch(`/delivery-checking/confirm`, { ids });
  }

  async createBatch(data: EditBatchInfo): Promise<{ batch: { id: number } }> {
    return wareHouseAxios.instance.post(`/delivery-checking`, data);
  }

  async checkOrderNoteByBarcode(barcode: string): Promise<{ note: string }> {
    return wareHouseAxios.instance.get(`/delivery-checking/order-note?barcode=${barcode}`);
  }

  async addItemToBatch({ barcode, batchId }: { batchId: number; barcode: string }): Promise<{ item: DeliveryBatchItem }> {
    return wareHouseAxios.instance.patch(`/delivery-checking/item`, { barcode, delivery_batch_id: batchId });
  }

  async deleteItemFromBatch(id: number): Promise<{ id: number }> {
    return wareHouseAxios.instance.delete(`/delivery-checking/items/${id}`);
  }

  async getExportedOrders(query: any): Promise<{ pagination: IListResponseMeta } & { items: DeliveryBatchItem[] }> {
    return wareHouseAxios.instance.get(`/delivery-checking/item?${queryString.stringify(query)}`);
  }

  async exportOrder(barcode: string): Promise<{ delivery_batch_item: DeliveryBatchItem }> {
    return wareHouseAxios.instance.post('/delivery-checking/item', { barcode });
  }
}

const deliveryBatchApi = new DeliveryBatchApi();

export default deliveryBatchApi;
