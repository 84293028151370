import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router';
import cleanDeep from 'clean-deep';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const customNavigate = (value: any) => {
    navigate({
      pathname: location.pathname,
      search: queryString.stringify(cleanDeep(value))
    });
  };

  return customNavigate;
};
