// project import

// import widget from './widget';
// import formsTables from './forms-tables';
// import chartsMap from './charts-map';
// import other from './other';
// import pages from './pages';

// types
import { NavItemType } from 'types/menu';
import humanResourcesManagement from './hr-manage';
import systemManagement from './system';
import { AppstoreAddOutlined } from '@ant-design/icons';

import me from './me';
import saleManagement from './sale';
import marketingManagement from './marketing';
import accountingManagement from './accounting';
import wareHouse from './ware-house';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: NavItemType = {
  id: 'group-sidebar',
  title: 'MENU',
  icon: AppstoreAddOutlined,
  type: 'group',
  children: [me, humanResourcesManagement, saleManagement, marketingManagement, accountingManagement, wareHouse, systemManagement]
};

export default menuItems;
