import { KEYS } from 'constants/key';

export const getLocalStorage = (key: string): string => {
  try {
    return String(JSON.parse(localStorage.getItem(key) || ''));
  } catch (error) {
    return '';
  }
};

export const setLocalStorage = (key: string, value: any) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const getAccessToken = () => getLocalStorage(KEYS.localStorageKey.accessToken);
export const setAccessToken = (token: string) => setLocalStorage(KEYS.localStorageKey.accessToken, token);
export const removeAccessToken = () => localStorage.removeItem(KEYS.localStorageKey.accessToken);

export const getRefreshToken = () => getLocalStorage(KEYS.localStorageKey.refreshToken);
export const setRefreshToken = (token: string) => setLocalStorage(KEYS.localStorageKey.refreshToken, token);
export const removeRefreshToken = () => localStorage.removeItem(KEYS.localStorageKey.refreshToken);
