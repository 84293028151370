import { useQuery } from '@tanstack/react-query';
import auth from 'api/new-auth';
import { KEYS } from 'constants/key';
import { authActions } from 'modules/auth/slice';
import { useDispatch } from 'store';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();

  useQuery({
    queryKey: [KEYS.query.meRole],
    queryFn: async () => {
      const response = await auth.getAuthRole();
      dispatch(authActions.updateAuthRole(response.role_actions));
      return response;
    }
  });
};
