export const APP = {
  defaultPageSize: 20,
  drawerWidth: 260,
  horizontalMaxItem: 6,
  hrserviceRoute: 'hr-api',
  timekeepingserviceRoute: 'timekeeping-api',
  saleserviceRoute: 'sale-api',
  complaintName: 'complaint',
  zaloPointName: 'zalo-point'
};
