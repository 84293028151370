export const ROUTE_URL = {
  home: '/',
  employee: {
    list: '/nhan-vien',
    goToDetail: (id: number | string) => `/nhan-vien/${id}`,
    add: '/nhan-vien/tao-moi'
  },
  role: {
    list: '/vai-tro',
    create: '/vai-tro/them-moi',
    goToDetail: (id: number | string) => `/vai-tro/${id}`
  },
  me: {
    profile: '/tai-khoan',
    changePassword: '/tai-khoan/doi-mat-khau'
  },
  auth: {
    login: '/dang-nhap',
    forgotPassword: '/quen-mat-khau',
    resetPassword: '/dat-lai-mat-khau'
  },
  location: '/chi-nhanh',
  department: '/phong-ban',
  position: '/chuc-vu',
  resource: '/resource',
  service: '/service',
  wareHouse: {
    delivery: {
      list: '/kho-hang/giao-hang',
      detail: '/kho-hang/giao-hang/:id',
      goToDetail: (id: string | number) => `/kho-hang/giao-hang/${id}`,
      create: '/kho-hang/giao-hang/them-moi'
    },
    exportOrder: {
      list: '/kho-hang/xuat-kho'
    },
    inventory: {
      list: '/kho-hang/kiem-ke',
      detail: '/kho-hang/kiem-ke/:id',
      goToDetail: (id: string | number) => `/kho-hang/kiem-ke/${id}`
    }
  },
  tiktokAuth: '/tiktok-auth',
  crawlData: '/crawl-data'
};

export const TIMEKEEPING_ROUTES = {
  report: '/bao-cao-cham-cong',
  timekeepingTickets: {
    list: '/phieu-cham-cong',
    detail: '/phieu-cham-cong/:id',
    goToDetail: (id: number | string) => `/phieu-cham-cong/${id}`
  },
  systemTimekeepingTickets: {
    list: '/cham-cong-he-thong',
    detail: '/cham-cong-he-thong/:id',
    goToDetail: (id: number | string) => `/cham-cong-he-thong/${id}`
  },
  timekeepingHistory: {
    list: '/lich-su-cham-cong'
  },
  onLeave: {
    list: '/phieu-nghi-phep',
    detail: '/phieu-nghi-phep/:id',
    goToDetail: (id: number | string) => `/phieu-nghi-phep/${id}`
  },
  violation: {
    list: '/phieu-sai-pham',
    detail: '/phieu-sai-pham/:id',
    goToDetail: (id: number | string) => `/phieu-sai-pham/${id}`,
    create: '/phieu-sai-pham/tao-sai-pham',
    point: {
      list: '/phieu-sai-pham/diem-sai-pham',
      detail: '/phieu-sai-pham/diem-sai-pham/:id',
      goToDetail: (id: number | string) => `/phieu-sai-pham/diem-sai-pham/${id}`
    }
  }
};

export const SALE_ROUTES = {
  productLabel: '/nhan-san-pham',
  orderNote: '/ghi-chu-don-hang',
  complaint: '/khieu-nai',
  complaintDetail: '/khieu-nai/:id',
  goToComplaintDetail: (id: number | string) => `/khieu-nai/${id}`,
  inStoreReport: 'bao-cao-kinh-doanh/kinh-doanh-tai-cua-hang',
  employeeRenenueReport: 'bao-cao-kinh-doanh/doanh-so-nhan-vien',
  eCommerceChanelOrderGift: '/qua-tang-tmdt'
};

export const MARKETING_ROUTES = {
  miniApp: {
    promotionList: '/zalo-mini-app/promotion',
    UIManagement: '/zalo-mini-app/quan-ly-ui',
    tagManagement: '/zalo-mini-app/quan-ly-tag',
    goToTagManagementDetail: (id: string | number) => `/zalo-mini-app/quan-ly-tag/${id}`,
    report: {
      chart: '/zalo-mini-app/',
      openAppState: '/zalo-mini-app/bao-cao/so-lan-mo-app',
      pointState: '/zalo-mini-app/bao-cao/phat-sinh-diem',
      user: '/zalo-mini-app/bao-cao/nguoi-dung',
      voucher: '/zalo-mini-app/bao-cao/qua-da-doi',
      accessHistoryCount: '/zalo-mini-app/bao-cao/luot-truy-cap',
      recentInteract: '/zalo-mini-app/bao-cao/tuong-tac-gan-day'
    },
    news: {
      list: '/zalo-mini-app/tin-tuc',
      add: '/zalo-mini-app/tin-tuc/them',
      detail: '/zalo-mini-app/tin-tuc/:id',
      goToDetail: (id: number | string) => `/zalo-mini-app/tin-tuc/${id}`
    }
  },
  tools: {
    menuGenerator: '/marketing/menu-generator'
  },
  productManagement: '/marketing/quan-ly-san-pham',
  report: {
    retailByChanel: '/marketing/bao-cao/ban-le-theo-kenh',
    lowSelling: '/marketing/bao-cao/san-pham-ban-khong-chay',
    shopeeDebt: '/marketing/bao-cao/cong-no-shopee',
    selling: '/marketing/bao-cao/tinh-hinh-ban-hang',
    profitMarginRateByProduct: '/marketing/bao-cao/ty-suat-loi-nhuan-theo-san-pham',
    revenueByChannel: '/marketing/bao-cao/doanh-thu-theo-kenh'
  },
  fbChatbotResponseManagement: '/marketing/quan-ly-chatbot-fb'
};

export const ACCOUNTING_ROUTE = {
  report: {
    shopeeDebt: '/ke-toan/bao-cao/cong-no-shopee',
    ghnDebt: '/ke-toan/bao-cao/cong-no-ghn',
    tiktokDebt: '/ke-toan/bao-cao/cong-no-tiktok'
  }
};
