import {
  AlertOutlined,
  BarChartOutlined,
  GiftOutlined,
  OneToOneOutlined,
  ShopOutlined,
  SnippetsOutlined,
  TagsOutlined
} from '@ant-design/icons';
import { PERMISSIONS } from 'constants/permission';
import { SALE_ROUTES } from 'constants/route';
import { NavItemType } from 'types/menu';

const saleManagement: NavItemType = {
  id: 'sale',
  title: 'Kinh doanh',
  type: 'collapse',
  icon: ShopOutlined,
  children: [
    // {
    //   id: 'report',
    //   title: 'Thống kê',
    //   type: 'item',
    //   icon: BarChartOutlined,
    //   url: SALE_ROUTES.salesReport,
    //   permission: PERMISSIONS.saleReport
    // },
    {
      id: 'report',
      title: 'Thống kê',
      type: 'collapse',
      icon: BarChartOutlined,
      children: [
        {
          id: 'in-store-sale',
          title: 'Cửa hàng',
          // icon: ShopOutlined,
          type: 'item',
          url: SALE_ROUTES.inStoreReport,
          permission: PERMISSIONS.saleReport
        },
        {
          id: 'employee-revenue',
          title: 'Doanh số nhân viên',
          // icon: OneToOneOutlined,
          type: 'item',
          url: SALE_ROUTES.employeeRenenueReport,
          permission: PERMISSIONS.saleReport
        }
      ]
    },
    // {
    //   id: 'product-label',
    //   title: 'Nhãn sản phẩm',
    //   type: 'item',
    //   icon: TagsOutlined,
    //   url: SALE_ROUTES.productLabel
    // },

    {
      id: 'complaint',
      title: 'Khiếu nại',
      type: 'item',
      icon: AlertOutlined,
      url: SALE_ROUTES.complaint,
      permission: 'customer-complaint'
    }
  ]
};

export default saleManagement;
