import { ReactNode, memo } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'utils/toast';
import useAuthAction from 'hooks/useAuthAction';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorRender from 'components/ErrorRender';
import { showMessageBadRequestError } from 'utils/error-message-handler';
import { hasserviceRoute } from 'utils/check-error';
import { API_INFO } from 'constants/api';

export default withErrorBoundary(
  memo(({ children }: { children: ReactNode }) => {
    const { logout } = useAuthAction();
    const errorHandler = (error: unknown) => {
      if (hasserviceRoute(error) && error.error instanceof AxiosError && error.error.response) {
        switch (error.error.response.status) {
          // bad request error
          case 400:
            showMessageBadRequestError(error.error.response.data, API_INFO[error.serviceRoute]);
            break;

          // unauthorized error
          case 401:
            logout();
            break;

          // forbidden error
          case 403:
            toast.error('Bạn không có quyền sử dụng tính năng này, vui lòng liên hệ Quản trị viên nếu có thắc mắc');
            break;

          // server error
          default:
            toast.error('Lỗi hệ thống');
            break;
        }
      } else {
        toast.error('Lỗi hệ thống');
      }
    };

    const queryCache = new QueryCache({
      onError(error, query) {
        errorHandler(error);
      }
    });

    const mutationCache = new MutationCache({
      onError(error, variables, context, mutation) {
        errorHandler(error);
      }
    });

    const queryClient = new QueryClient({
      mutationCache,
      queryCache,
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false
        }
      }
    });

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
  }),
  {
    FallbackComponent: ErrorRender
  }
);
