import useAuth from 'hooks/useAuth';
import Forbidden from 'pages/maintenance/403';
import { ComponentType } from 'react';

export default function withPermission(Component: ComponentType, resourceCode: string) {
  return () => {
    const { userRoleActions: roleActions } = useAuth();

    const compatibleResource = roleActions?.find((item) => item.resource_code === resourceCode);
    if (!compatibleResource) return <Forbidden />;

    if (!compatibleResource.actions.reduce((result, item) => result || item.is_allowed, false)) return <Forbidden />;

    return <Component />;
  };
}
