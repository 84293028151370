import { BarChartOutlined, CalculatorOutlined } from '@ant-design/icons';
import { PERMISSIONS } from 'constants/permission';
import { ACCOUNTING_ROUTE } from 'constants/route';
import { NavItemType } from 'types/menu';

const accountingManagement: NavItemType = {
  id: 'accounting',
  title: 'Kế toán',
  type: 'collapse',
  icon: CalculatorOutlined,
  children: [
    {
      id: 'report',
      title: 'Báo cáo',
      type: 'collapse',
      icon: BarChartOutlined,
      children: [
        {
          id: 'shopee-debt',
          title: 'Công nợ Shopee',
          type: 'item',
          url: ACCOUNTING_ROUTE.report.shopeeDebt,
          permission: PERMISSIONS.accountingReport
        },
        {
          id: 'ghn-debt',
          title: 'Công nợ GHN',
          type: 'item',
          url: ACCOUNTING_ROUTE.report.ghnDebt,
          permission: PERMISSIONS.accountingReport
        },
        {
          id: 'tiktok-debt',
          title: 'Công nợ Tiktok',
          type: 'item',
          url: ACCOUNTING_ROUTE.report.tiktokDebt,
          permission: PERMISSIONS.accountingReport
        }
      ]
    }
  ]
};

export default accountingManagement;
