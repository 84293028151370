import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ProfileOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { ROUTE_URL } from 'constants/route';

interface Props {
  handleLogout: () => void;
}

function getPathIndex(pathname: string) {
  let selectedTab = 0;
  switch (pathname) {
    case '/me':
      selectedTab = 1;
      break;
    case '/doi-mat-khau':
      selectedTab = 2;
      break;
    default:
      selectedTab = 0;
  }
  return selectedTab;
}

const Profile = ({ handleLogout }: Props) => {
  const { pathname } = useLocation();
  const selectedIndex = getPathIndex(pathname);

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton selected={selectedIndex === 1} component={Link} to={ROUTE_URL.me.profile}>
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="Cá nhân" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 2} component={Link} to={ROUTE_URL.me.changePassword}>
        <ListItemIcon>
          <ProfileOutlined />
        </ListItemIcon>
        <ListItemText primary="Đổi mật khẩu" />
      </ListItemButton>

      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default Profile;
