import {
  AimOutlined,
  ApartmentOutlined,
  DatabaseFilled,
  PicCenterOutlined,
  RadiusSettingOutlined,
  SafetyCertificateOutlined,
  TikTokOutlined
} from '@ant-design/icons';
import { PERMISSIONS } from 'constants/permission';
import { ROUTE_URL } from 'constants/route';
import { NavItemType } from 'types/menu';

const systemManagement: NavItemType = {
  id: 'system',
  title: 'Quản lý hệ thống',
  type: 'collapse',
  icon: SafetyCertificateOutlined,
  children: [
    {
      id: 'location',
      title: 'Chi nhánh',
      type: 'item',
      icon: AimOutlined,
      url: ROUTE_URL.location,
      permission: PERMISSIONS.location
    },
    {
      id: 'role',
      title: 'Vai trò',
      type: 'item',
      icon: RadiusSettingOutlined,
      url: ROUTE_URL.role.list,
      permission: PERMISSIONS.role
    },
    {
      id: 'resource',
      title: 'Resource',
      type: 'item',
      icon: PicCenterOutlined,
      url: ROUTE_URL.resource,
      permission: PERMISSIONS.resource
    },
    {
      id: 'service',
      title: 'Service',
      type: 'item',
      icon: ApartmentOutlined,
      url: ROUTE_URL.service,
      permission: PERMISSIONS.service
    },
    {
      id: 'tiktok-auth',
      title: 'Tiktok Auth',
      type: 'item',
      icon: TikTokOutlined,
      url: ROUTE_URL.tiktokAuth,
      permission: PERMISSIONS.tiktokAuth
    },
    {
      id: 'crawl-data',
      title: 'Crawl Data',
      type: 'item',
      icon: DatabaseFilled,
      url: ROUTE_URL.crawlData,
      permission: PERMISSIONS.crawlData
    }
  ]
};

export default systemManagement;
