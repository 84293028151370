// project import
import ThemeCustomization from 'themes';

// import Locales from "components/Locales";
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
// import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
// import { PersistGate } from 'redux-persist/integration/react';
// import { Provider } from 'react-redux';

// import { dispatch } from 'store';
// import { fetchMenu } from 'store/reducers/menu';

// auth-provider
// import { JWTProvider as AuthProvider } from "contexts/JWTContext";

// import QueryProvider from "QueryProvider";
import Routes from 'routes';
import FullScreenLoading from 'modules/app/components/FullScreenLoading';
import 'swiper/css';
import 'swiper/css/navigation';

// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  return (
    <ThemeCustomization>
      <RTLLayout>
        <ScrollTop>
          <Notistack>
            <Routes />
            <FullScreenLoading />
          </Notistack>
        </ScrollTop>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
