import { EException } from 'types/response-code/chot-ca';

export const chotCaErrorMessageInfo: Record<EException, string> = {
  '9': 'Không tìm thấy dữ liệu',
  '4': 'Lỗi từ hệ thống Sapogo',
  '7': 'Có tiến trình đang được xử lý, vui lòng thử lại sau',
  '1100': 'Quà tặng của đơn hàng đã được xuất kho, không thể chỉnh sửa',
  '1101': 'Đơn hàng chưa được thêm quà tặng nên không thể cập nhật',
  '1102': 'Đơn hàng không có sản phẩm là Whey hoặc Mass',
  '-1': 'Vui lòng kiểm tra lại thông tin'
};
