import { UserOutlined } from '@ant-design/icons';
import { ROUTE_URL } from 'constants/route';
import { NavItemType } from 'types/menu';

const me: NavItemType = {
  id: 'me',
  title: 'Cá nhân',
  type: 'collapse',
  icon: UserOutlined,
  children: [
    {
      id: 'me',
      title: 'Thông tin của tôi',
      type: 'item',
      icon: UserOutlined,
      url: ROUTE_URL.me.profile
    }
  ]
};

export default me;
