import {
  ContainerOutlined,
  DeploymentUnitOutlined,
  MobileOutlined,
  SettingOutlined,
  ProductOutlined,
  BarChartOutlined,
  ShoppingCartOutlined,
  AppstoreAddOutlined,
  RobotOutlined,
  TeamOutlined,
  DollarOutlined,
  GiftOutlined,
  FileTextOutlined,
  FrownOutlined,
  ToolOutlined
} from '@ant-design/icons';
import { PERMISSIONS } from 'constants/permission';
import { MARKETING_ROUTES } from 'constants/route';
import { NavItemType } from 'types/menu';

const marketingManagement: NavItemType = {
  id: 'marketing',
  title: 'Marketing',
  type: 'collapse',
  icon: DeploymentUnitOutlined,
  children: [
    {
      id: 'report',
      title: 'Thống kê',
      type: 'collapse',
      icon: BarChartOutlined,
      children: [
        {
          id: 'retail-report-by-chanel',
          title: 'Bán lẻ theo kênh',
          type: 'item',
          url: MARKETING_ROUTES.report.retailByChanel,
          permission: PERMISSIONS.productManagement
        },
        {
          id: 'low-selling',
          title: 'SP bán không chạy',
          type: 'item',
          url: MARKETING_ROUTES.report.lowSelling,
          permission: PERMISSIONS.productManagement
        },
        {
          id: 'selling',
          title: 'Tình hình bán hàng',
          type: 'item',
          url: MARKETING_ROUTES.report.selling,
          permission: PERMISSIONS.productManagement
        },
        {
          id: 'profit-margin-rate-by-product',
          title: 'Tỷ suất lợi nhuận',
          type: 'item',
          url: MARKETING_ROUTES.report.profitMarginRateByProduct,
          permission: PERMISSIONS.productManagement
        },
        {
          id: 'revenue-by-channel',
          title: 'Doanh thu theo kênh',
          type: 'item',
          url: MARKETING_ROUTES.report.revenueByChannel,
          permission: PERMISSIONS.productManagement
        }
      ]
    },
    {
      id: 'zalo-mini-app',
      title: 'Zalo Mini App',
      type: 'collapse',
      icon: MobileOutlined,
      children: [
        {
          id: 'report',
          title: 'Báo cáo',
          type: 'collapse',
          children: [
            {
              id: 'open-app-state',
              title: 'Tình hình mở app',
              type: 'item',
              url: MARKETING_ROUTES.miniApp.report.openAppState,
              permission: PERMISSIONS.miniAppSettings
            },
            {
              id: 'point-state',
              title: 'Tình hình tích điểm',
              type: 'item',
              url: MARKETING_ROUTES.miniApp.report.pointState,
              permission: PERMISSIONS.miniAppSettings
            },
            {
              id: 'user-state',
              title: 'Người dùng',
              type: 'item',
              url: MARKETING_ROUTES.miniApp.report.user,
              permission: PERMISSIONS.miniAppSettings
            },
            {
              id: 'voucher-state',
              title: 'Quà đã đổi',
              type: 'item',
              url: MARKETING_ROUTES.miniApp.report.voucher,
              permission: PERMISSIONS.miniAppSettings
            },
            {
              id: 'access-history-count',
              title: 'Lượt truy cập',
              type: 'item',
              url: MARKETING_ROUTES.miniApp.report.accessHistoryCount,
              permission: PERMISSIONS.miniAppSettings
            },
            {
              id: 'recent-interact',
              title: 'Tương tác gần đây',
              type: 'item',
              url: MARKETING_ROUTES.miniApp.report.recentInteract,
              permission: PERMISSIONS.miniAppSettings
            }
          ]
        },
        {
          id: 'promotion',
          title: 'Promotion',
          type: 'item',
          // icon: ContainerOutlined,
          url: MARKETING_ROUTES.miniApp.promotionList,
          permission: PERMISSIONS.promotion
        },
        {
          id: 'promotion-tags',
          title: 'Quản lý nhóm quà',
          type: 'item',
          // icon: AppstoreAddOutlined,
          permission: PERMISSIONS.miniAppSettings,
          url: MARKETING_ROUTES.miniApp.tagManagement
        },
        {
          id: 'news',
          title: 'Quản lý Tin tức',
          type: 'item',
          // icon: FileTextOutlined,
          url: MARKETING_ROUTES.miniApp.news.list,
          permission: PERMISSIONS.miniAppSettings
        },
        {
          id: 'ui-management',
          title: 'Quản lý UI',
          type: 'item',
          // icon: SettingOutlined,
          url: MARKETING_ROUTES.miniApp.UIManagement,
          permission: PERMISSIONS.miniAppSettings
        }
      ]
    },
    {
      id: 'quan-ly-san-pham',
      title: 'Quản lý sản phẩm',
      type: 'item',
      icon: ProductOutlined,
      url: MARKETING_ROUTES.productManagement,
      permission: PERMISSIONS.productManagement
    },
    {
      id: 'quan-ly-chatbot-fb',
      title: 'Quản lý Chatbot FB',
      type: 'item',
      icon: GiftOutlined,
      url: MARKETING_ROUTES.fbChatbotResponseManagement,
      permission: PERMISSIONS.chatbotFBManagement
    },
    {
      id: 'menu-generator',
      title: 'Tạo blog menu',
      type: 'item',
      icon: ToolOutlined,
      url: MARKETING_ROUTES.tools.menuGenerator
    }
  ]
};

export default marketingManagement;
