import { EHRBadRequestErrorCode } from 'types/response-code/hr-code';

export const hrErrorMessageInfo: Record<EHRBadRequestErrorCode, string> = {
  '-1': 'Vui lòng kiểm tra lại thông tin',
  '100': 'Sai thông tin đăng nhập',
  '101': 'Link lấy lại mật khẩu không hợp lệ hoặc hết hạn',
  '102': 'Email không tồn tại',
  // account
  '200': 'Sai mật khẩu hiện tại',
  '201': 'Không tìm thấy người dùng này',
  '203': 'Chỉ cho phép upload hình ảnh',
  '204': 'Thiếu hình ảnh, vui lòng thử lại sau',
  // employee
  '300': 'Nhân viên không tồn tại',
  '301': 'Email hoặc số điện thoại này đã được sử dụng bởi nhân viên khác',
  '302': 'Nhân viên này đang trong trạng thái kích hoạt',
  '303': 'Nhân viên này đang trong trạng thái ngưng kích hoạt',
  '304': 'Nhân viên này chưa có tài khoản Sapogo',
  '305': 'Nhân viên này đã có tài khoản Sapogo',
  '306': 'Trùng username trên Confluence',
  '307': 'Nhân viên đã có tài khoản Confluence',
  '308': 'Lỗi Confluence',
  '309': 'Nhân viên đã có tài khoản Mattermost',
  '310': 'Tài khoản Mattermost đã tồn tại',
  '311': 'Lỗi server Mattermost',
  '312': 'Tài khoản Mattermost ko tìm thấy',
  '313': 'Lỗi khi thêm Vào team Mattermost',
  // role
  '400': 'Resource bị trùng',
  '401': 'Hành động bị trùng',
  '402': 'Vai trò bị trùng',
  '403': 'Resource không tìm thấy',
  '404': 'Vai trò không tìm thấy',
  '405': 'Hành động của vai trò không tìm thấy',
  '406': 'Hành động không tìm thấy',
  '407': 'Vai trò không thể bị xóa do đang có nhân viên sử dụng',
  '408': 'Resource không thể bị xóa do đang có nhân viên sử dụng',
  // location
  '500': 'Chi nhánh không tìm thấy',
  '501': 'Chi nhánh trùng tên',
  '502': 'Chi nhánh không thể xóa do có nhân viên đang ở chi nhánh này',
  // department
  '600': 'Phòng ban không tìm thấy',
  '601': 'Phòng ban trùng tên',
  '602': 'Phòng ban không thể xóa do có nhân viên đang thuộc phòng ban này',
  // position
  '700': 'Chức vụ không tìm thấy',
  '701': 'Chức vụ trùng tên trong phòng ban',
  '702': 'Chức vụ không thể xóa do có nhân viên đang đảm nhiệm chức vụ này'
};
