import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import

// types
// import { GuardProps } from 'types/auth';
import useAuth from 'hooks/useAuth';
import { ROUTE_URL } from 'constants/route';

import useAuthRole from 'hooks/useAuthRole';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorRender from 'components/ErrorRender';
import { KEYS } from 'constants/key';

// ==============================|| AUTH GUARD ||============================== //

const notInRedirect = [ROUTE_URL.me.changePassword];

const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();

  const location = useLocation();

  const isLogin = useAuth().isLogin;

  useAuthRole();

  useEffect(() => {
    if (!isLogin) {
      const queryStr = notInRedirect.includes(location.pathname)
        ? ''
        : `?${KEYS.app.redirectAfterLogin}=${location.pathname}${location.search}`;
      navigate(`${ROUTE_URL.auth.login}${queryStr}`);
    }
  }, [isLogin]);

  return children;
};

export default withErrorBoundary(AuthGuard, {
  FallbackComponent: ErrorRender
});
