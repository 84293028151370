import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Stack, Typography } from '@mui/material';

export default function Forbidden() {
  return (
    <Stack alignItems={'center'} justifyContent={'center'} minHeight={300}>
      <ExclamationCircleOutlined
        style={{
          fontSize: 80,
          marginBottom: 16
        }}
      />
      <Typography variant="h3" mb={1}>
        Bạn không có quyền truy cập vào trang này
      </Typography>
      <Typography>Vui lòng liên hệ quản trị viên (nếu cần).</Typography>
    </Stack>
  );
}
