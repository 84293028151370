import { useRoutes } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import Error404 from 'pages/maintenance/404';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorRender from 'components/ErrorRender';

// ==============================|| ROUTING RENDER ||============================== //
function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    LoginRoutes,
    {
      path: '*',
      element: <Error404 />
    }
  ]);
}

export default withErrorBoundary(ThemeRoutes, {
  FallbackComponent: ErrorRender
});
