// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TAB ||============================== //

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          // '.MuiSelect-select': { paddingTop: '8px', paddingBottom: '8px' }
        }
      }
    }
  };
}
