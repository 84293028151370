import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Stack } from '@mui/material';
import { RootState, useSelector } from 'store';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const isLoading = useSelector((state: RootState) => state.app.isLoadingFullScreen);
  return (
    <>
      {isLoading && (
        <Modal open={true}>
          <Stack
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <LoadingOutlined
              style={{
                color: '#fff',
                fontSize: 60
              }}
            />
          </Stack>
        </Modal>
      )}
    </>
  );
};
