import { authAxios } from 'constants/api';
import { getRefreshToken, setAccessToken, setRefreshToken } from 'utils/local-storage';

class Auth {
  async login(body: ILoginForm): Promise<ILoginResponse> {
    return authAxios.instance.post('/login', body);
  }

  async renewAccessToken() {
    const response: { refresh_token: string; access_token: string } = await authAxios.instance.post('/refresh-token', {
      refresh_token: getRefreshToken()
    });

    setRefreshToken(response.refresh_token);
    setAccessToken(response.access_token);
  }

  async logout() {
    return authAxios.instance.post('/logout', {
      refresh_token: getRefreshToken()
    });
  }

  async me(): Promise<IProfile> {
    return authAxios.instance.get('/me');
  }

  async changeAvatar(formData: FormData): Promise<string> {
    return authAxios.instance.patch('/avatar', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  async changePassword(data: { old_password: string; new_password: string }) {
    return authAxios.instance.patch('/change-password', data);
  }

  async getAuthRole(): Promise<{ role_actions: IAuthRoleAction[] }> {
    return authAxios.instance.get('/role');
  }

  async forgotPassword(data: IForgotPassword) {
    return authAxios.instance.post('/forgot-password', data);
  }

  async resetPassword(data: { new_password: string; reset_token: string }) {
    return authAxios.instance.patch('/reset-password', data);
  }
}

const auth = new Auth();

export default auth;
