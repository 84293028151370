import { EErrorCode } from 'types/response-code/ware-house';

export const wareHouseErrorMessageInfo: Record<EErrorCode, string> = {
  '-1': 'Vui lòng kiểm tra lại thông tin',
  '100': 'Không tìm thấy đơn hàng',
  '101': 'Đơn hàng đã có ghi chú',
  '102': 'Đơn hàng đã xuất kho',
  '103': 'ĐƠn hàng đã bị hủy',
  '200': 'Không tìm thấy ghi chú',
  '201': 'Không tìm thấy đợt giao hàng',
  '202': 'Đợt giao hàng này đã được giao',
  '203': 'Đơn hàng đã ở trong đợt giao hàng này',
  '204': 'Đơn hàng đã ở trong 1 đợt giao hàng khác',
  '205': 'Không tìm thấy vận đơn này',
  '206': 'Đơn hàng đã hủy đóng gói',
  '207': 'Đơn hàng đang được giao',
  '208': 'Đơn hàng đang chờ giao lại',
  '209': 'Đơn hàng đang được hủy giao chờ nhận',
  '210': 'Đơn hàng hủy giao đã nhận',
  '211': 'Đơn hàng đã giao',
  '212': 'Đơn hàng đã hủy',
  '214': 'Nhân viên chưa được tạo tài khoản Sapo',
  '215': 'Có lỗi xảy ra khi cập nhật Sapo',
  '216': 'Không tìm thấy đơn hàng trong hệ thống đợt giao hàng',
  '217': 'Đơn hàng này đã được xuất kho',
  '218': 'Đơn hàng chưa được xuất kho'
};
