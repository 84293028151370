import _ from 'lodash';
import queryString from 'query-string';
import { useLocation } from 'react-router';

// eslint-disable-next-line import/no-anonymous-default-export
export default (keys?: string[]) => {
  const location = useLocation();

  const query = queryString.parse(location.search);

  return keys ? _.pick(query, keys) : query;
};
