import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import * as reducers from './reducers';
import { initialState } from './initial-state';
import { KEYS } from 'constants/key';

const slice = createSlice({
  name: 'auth-slice',
  initialState: initialState,
  reducers
});

const configSlice = persistReducer({ key: KEYS.redux.authUser, storage: localStorage }, slice.reducer);

export const authActions = { ...slice.actions };

export default configSlice;
