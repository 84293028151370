// material-ui
import { Table, TableBody, TableCell, TableHead, TableRow, Skeleton, Box } from '@mui/material';

// third-party
import { HeaderGroup, Cell, Column, useSortBy, useTable } from 'react-table';

// project import
import { HeaderSort } from 'components/third-party/ReactTable';
import { forwardRef, Ref } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorRender from './ErrorRender';
import PagePagination from './Pagination';

// ==============================|| REACT TABLE ||============================== //

const MyTable = forwardRef(function MyTable(
  {
    striped,
    count,
    loading,
    columns,
    data,
    hiddenPagination = false,
    headerSort = false,
    isDrag = false,
    fixedHeader = true
  }: {
    columns: Column<any>[];
    data: any[];
    loading?: boolean;
    striped?: boolean;
    count?: number;
    startAction?: boolean;
    endAction?: boolean;
    hiddenPagination?: boolean;
    headerSort?: boolean;
    isDrag?: boolean;
    fixedHeader?: boolean;
  },
  ref: Ref<any>
) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: []
      }
    },
    useSortBy
  );

  const getHeaderProps = (column: HeaderGroup) => column.getSortByToggleProps();

  let sortedData = rows.map((d) => d.original);

  Object.keys(sortedData).forEach((key: string) => sortedData[Number(key)] === undefined && delete sortedData[Number(key)]);

  return (
    <>
      <Box flexGrow={fixedHeader ? 1 : undefined} overflow={'auto'} ref={ref}>
        <Table {...getTableProps()} sx={{ maxHeight: '100%' }}>
          <TableHead className={`${fixedHeader ? 'sticky-header' : ''}`}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: HeaderGroup) => (
                  <TableCell
                    {...column.getHeaderProps([{ className: column.className }, getHeaderProps(column)])}
                    sx={{
                      textTransform: 'inherit',
                      fontWeight: 500
                    }}
                  >
                    <HeaderSort column={column} sort={headerSort} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {loading ? (
            <TableBody
              sx={{
                position: 'relative',
                height: 200
              }}
            >
              <TableRow>
                {columns.map((heading, index) => (
                  <TableCell key={index}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {columns.map((heading, index) => (
                  <TableCell key={index}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {columns.map((heading, index) => (
                  <TableCell key={index}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {columns.map((heading, index) => (
                  <TableCell key={index}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {columns.map((heading, index) => (
                  <TableCell key={index}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          ) : (
            <TableBody {...getTableBodyProps()} {...(striped && { className: 'striped' })}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell: Cell) => (
                      <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </Box>
      {!hiddenPagination && <PagePagination count={count} curLength={rows.length} />}
    </>
  );
});

export default withErrorBoundary(MyTable, {
  FallbackComponent: ErrorRender
});
