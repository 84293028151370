import { PayloadAction } from '@reduxjs/toolkit';
import auth from 'api/new-auth';
import { removeAccessToken, removeRefreshToken, setAccessToken, setRefreshToken } from 'utils/local-storage';

export const login = (state: IAuthState, action: PayloadAction<ILoginResponse>) => {
  state.isLogin = true;
  state.user = action.payload.user;
  setAccessToken(action.payload.access_token);
  setRefreshToken(action.payload.refresh_token);
};

export const logout = (state: IAuthState) => {
  auth.logout();
  state.isLogin = false;
  removeAccessToken();
  removeRefreshToken();
  state.user = {};
};

export const changeAvatar = (state: IAuthState, action: PayloadAction<string>) => {
  state.user = state.user && {
    ...state.user,
    avatar: action.payload
  };
};

export const updateAuthRole = (state: IAuthState, action: PayloadAction<IAuthRoleAction[]>) => {
  state.userRoleActions = action.payload;
};
