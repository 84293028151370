import { SearchOutlined } from '@ant-design/icons';
import { IconButton, Stack, StackProps, TextField } from '@mui/material';
import useNavigateQuery from 'hooks/useNavigateQuery';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  placeholder,
  onSearchSubmit,
  ...rest
}: StackProps & { placeholder?: string; onSearchSubmit?: (search: string) => void }) => {
  const [search, setSearch] = useState<string>('');
  const navigate = useNavigateQuery();
  const [searchParams] = useSearchParams();
  useEffect(() => setSearch(searchParams.get('search') || ''), [searchParams]);

  const onSearch = () => {
    navigate({ search });
  };
  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems="center" {...rest}>
      <TextField
        sx={{
          typography: (theme) => ({ ...theme.typography.body1 }),
          color: 'info.main',
          '>*': {
            pl: '0 !important'
          },
          input: {
            pl: 0
          }
        }}
        placeholder={placeholder || 'Tìm kiếm'}
        fullWidth
        size="small"
        InputProps={{
          startAdornment: (
            <IconButton onClick={onSearch}>
              <SearchOutlined />
            </IconButton>
          )
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            onSearch();
          }
        }}
      />
    </Stack>
  );
};
