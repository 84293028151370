import { ReactNode } from 'react';
import { store, persister } from 'store';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { withErrorBoundary } from 'react-error-boundary';
import SomethingWrong from 'components/SomethingWrong';
import { KEYS } from 'constants/key';

export default withErrorBoundary(
  ({ children }: { children: ReactNode }) => {
    return (
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persister}>
          {children}
        </PersistGate>
      </ReduxProvider>
    );
  },
  {
    FallbackComponent: ({ resetErrorBoundary }) => <SomethingWrong onReset={resetErrorBoundary} />,
    onReset: () => {
      localStorage.removeItem(`persist:${KEYS.redux.authUser}`);
      window.location.reload();
    }
  }
);
