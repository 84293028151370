import { Modal } from '@mui/material';
import { FormEventHandler, ReactNode, forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import MainCard from './MainCard';
import { CardContent } from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';
import { Button } from '@mui/material';

interface Props {
  title?: string;
  children?: ReactNode;
  action?: ReactNode;
  minWidth?: string | number;
  maxWidth?: string | number;
  onSubmitForm?: FormEventHandler<HTMLFormElement>;
  onClose?: () => void;
  disableCloseOnClickOutside?: boolean;
}

export interface IModalRef {
  toggle: () => void;
  open: () => void;
  close: () => void;
}

export default forwardRef<IModalRef, Props>(
  ({ action, children, title, minWidth, onSubmitForm, onClose, maxWidth, disableCloseOnClickOutside }, ref) => {
    const [open, setOpen] = useState(false);

    const toggle = useCallback(() => {
      open && onClose && onClose();
      setOpen((p) => !p);
    }, [open, onClose]);

    useImperativeHandle(ref, () => ({
      toggle,
      close() {
        setOpen(false);
      },
      open() {
        setOpen(true);
      }
    }));

    let sx = {};

    if (minWidth) sx = { ...sx, minWidth };

    if (maxWidth) sx = { ...sx, maxWidth };

    return (
      <Modal open={open} onClose={disableCloseOnClickOutside ? undefined : toggle}>
        <MainCard sx={sx} title={title} modal darkTitle content={false}>
          <form onSubmit={onSubmitForm}>
            <CardContent>{children}</CardContent>
            <Divider />
            <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ px: 2.5, py: 2 }}>
              <Button variant="outlined" onClick={toggle}>
                Đóng
              </Button>
              {action}
            </Stack>
          </form>
        </MainCard>
      </Modal>
    );
  }
);
