import { Box, Button, Chip, Tooltip, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import deliveryBatchApi from 'api/delivery-batch';
import MainCard from 'components/MainCard';
import MyTable from 'components/MyTable';
import { KEYS } from 'constants/key';
import useQueryObject from 'hooks/useQueryObject';
import { DeliveryBatchItem } from 'modules/delivery/interface';
import { Column } from 'react-table';
import Scanner, { ScannerRef } from '../Scanner';
import SpecialNoteRemindModal, { SpecialNoteRemindRef } from './SpecialNoteRemindModal';
import { useRef } from 'react';
import { playFailSound, playSpecialNoteSound, playSuccessSound } from 'modules/delivery/sounds';
import { useNavigate } from 'react-router';
import { ROUTE_URL } from 'constants/route';
import Search from 'components/Search';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorRender from 'components/ErrorRender';
import withPermission from 'hoc/withPermission';
import { PERMISSIONS } from 'constants/permission';

function ExportOrderList() {
  const noteRemindRef = useRef<SpecialNoteRemindRef>(null);
  const scannerRef = useRef<ScannerRef>(null);

  const navigate = useNavigate();

  const params = useQueryObject(['page', 'limit', 'search']);

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: [KEYS.query.wareHouse.exportOrder, params],
    queryFn: () => deliveryBatchApi.getExportedOrders(params)
  });

  const readyForNextScan = () => {
    noteRemindRef.current?.close();
    scannerRef.current?.waitingForScanning();
  };

  const { mutate: exportOrder, isLoading: isExportingOrder } = useMutation({
    mutationFn: deliveryBatchApi.exportOrder,
    onSuccess(data, variables, context) {
      playSuccessSound();

      queryClient.setQueryData(
        [KEYS.query.wareHouse.exportOrder, params],
        (
          p:
            | (IListResponseMeta & {
                items: DeliveryBatchItem[];
              })
            | undefined
        ) =>
          p && {
            ...p,
            items: [data.delivery_batch_item, ...p.items]
          }
      );
      readyForNextScan();
    },
    onError() {
      playFailSound();
      readyForNextScan();
    }
  });

  const { mutate: checkOrderNote, isLoading: isCheckingOrderNote } = useMutation({
    mutationFn: deliveryBatchApi.checkOrderNoteByBarcode,
    onSuccess(data, variables, context) {
      if (data.note) {
        playSpecialNoteSound();
        noteRemindRef.current?.open(data.note, () => {
          exportOrder(variables);
        });
      } else {
        exportOrder(variables);
      }
    },
    onError() {
      playFailSound();
      readyForNextScan();
    }
  });

  const columns: Column<DeliveryBatchItem>[] = [
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true
    },
    {
      Header: 'Mã vận đơn',
      accessor: 'bar_code',
      disableSortBy: true
    },
    {
      Header: 'Mã đơn hàng',
      accessor: 'order_code',
      disableSortBy: true
    },
    {
      Header: 'Đơt giao hàng',
      accessor: 'delivery_batch_id',
      disableSortBy: true,
      Cell: ({ value }) =>
        value ? (
          <Tooltip title="Xem chi tiết đợt giao hàng">
            <Button
              size="small"
              onClick={() => {
                navigate(ROUTE_URL.wareHouse.delivery.goToDetail(value));
              }}
            >
              {value}
            </Button>
          </Tooltip>
        ) : (
          <></>
        )
    },
    {
      Header: 'Đã giao',
      accessor: 'delivered',
      disableSortBy: true,
      Cell: ({ value }) =>
        value ? <Chip size="small" label="Đã giao" color="success" /> : <Chip size="small" label="Chưa giao" color="warning" />
    },
    {
      Header: 'Nhân viên xuất kho',
      accessor: 'account_name',
      disableSortBy: true
    }
  ];

  return (
    <Box height={'100%'} overflow={'auto'}>
      <Typography>
        (Các bạn kho khi đóng hàng vui lòng{' '}
        <b>
          TREO SONG SONG{' '}
          <a href="https://one.thegioiwhey.com/ghi-chu-don-hang" target="_blank">
            TAB NÀY
          </a>
        </b>{' '}
        và nhấn nút <b>BẬT THÔNG BÁO</b> để nhận thông báo nếu có ghi chú mới)
      </Typography>
      <MainCard
        title="Quét mã vận đơn để xuất kho"
        sx={{
          height: 280,
          flexShrink: 0
        }}
      >
        <Scanner
          onScanSuccess={(code) => {
            checkOrderNote(code);
          }}
          disable={isCheckingOrderNote || isExportingOrder}
          ref={scannerRef}
        />
        <SpecialNoteRemindModal ref={noteRemindRef} />
      </MainCard>
      <Typography variant="h5">Danh sách đơn hàng đã xuất kho</Typography>

      <Search placeholder="Tìm kiếm theo mã vận đơn, mã đơn hàng, id đơn hàng" />
      <MyTable columns={columns} data={data?.items || []} count={data?.pagination.count} loading={isLoading} fixedHeader={false} />
    </Box>
  );
}

export default withErrorBoundary(withPermission(ExportOrderList, PERMISSIONS.packing), { FallbackComponent: ErrorRender });
