import { Pagination as MUIPagination, MenuItem, Select, Stack, Typography } from '@mui/material';
import { APP } from 'constants/app';
import useNavigateQuery from 'hooks/useNavigateQuery';
import useQueryObject from 'hooks/useQueryObject';
import { useMemo } from 'react';

function PagePagination({ curLength, count }: { curLength: number; count?: number }) {
  const navigate = useNavigateQuery();
  let query = useQueryObject();

  const page = useMemo(() => parseInt(String(query.page || 1), 10), [query.page]);
  const limit = useMemo(() => parseInt(String(query.limit || APP.defaultPageSize), 10), [query.limit]);

  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mx="20px">
      <Stack direction={'row'} alignItems={'center'}>
        <Typography variant="caption" mr={1} color={'secondary.main'}>
          Số hàng mỗi trang
        </Typography>
        <Select
          value={limit}
          onChange={(e) =>
            navigate({
              ...query,
              limit: e.target.value,
              page: null
            })
          }
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
        <Typography ml={2} variant="caption">{`Từ ${(page - 1) * limit + 1} đến ${
          (page - 1) * limit + 1 + curLength - 1
        } trên tổng số ${count} kết quả`}</Typography>
      </Stack>
      <MUIPagination
        page={page}
        count={Math.ceil((count || 0) / limit)}
        color="primary"
        onChange={(_e, value) =>
          navigate({
            ...query,
            page: value
          })
        }
        variant="outlined"
      />
    </Stack>
  );
}

export default PagePagination;
