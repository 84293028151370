import { DropboxOutlined, FileDoneOutlined, GiftOutlined, GoldOutlined, SnippetsOutlined, TruckOutlined } from '@ant-design/icons';
import { PERMISSIONS } from 'constants/permission';
import { ROUTE_URL, SALE_ROUTES } from 'constants/route';
import { NavItemType } from 'types/menu';

const wareHouse: NavItemType = {
  id: 'ware-house',
  title: 'Kho hàng',
  type: 'collapse',
  icon: GoldOutlined,
  children: [
    {
      id: 'delivery',
      title: 'Bàn giao vận đơn',
      type: 'item',
      icon: TruckOutlined,
      url: ROUTE_URL.wareHouse.delivery.list,
      permission: PERMISSIONS.packing
    },
    {
      id: 'export',
      title: 'Xuất kho',
      type: 'item',
      icon: DropboxOutlined,
      url: ROUTE_URL.wareHouse.exportOrder.list,
      permission: PERMISSIONS.packing
    },
    {
      id: 'order-note',
      title: 'Ghi chú đơn hàng',
      type: 'item',
      icon: SnippetsOutlined,
      url: SALE_ROUTES.orderNote,
      permission: PERMISSIONS.orderNote
    },
    {
      id: 'e-commerce-order-gift',
      title: 'Quà tặng đơn TMĐT',
      type: 'item',
      icon: GiftOutlined,
      url: SALE_ROUTES.eCommerceChanelOrderGift,
      permission: PERMISSIONS.ecommerceGift
    },
    {
      id: 'inventory',
      title: 'Kiểm kê',
      type: 'item',
      icon: FileDoneOutlined,
      url: ROUTE_URL.wareHouse.inventory.list,
      permission: PERMISSIONS.inventory
    }
  ]
};

export default wareHouse;
