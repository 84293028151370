import { createSlice } from '@reduxjs/toolkit';
import * as reducers from './reducers';
import { initialState } from './initial-state';

const slice = createSlice({
  name: 'app-slice',
  initialState: initialState,
  reducers
});

export const appActions = { ...slice.actions };

export default slice.reducer;
