import { combineReducers } from 'redux';
import menu from './menu';
import snackbar from './snackbar';
import authSlice from 'modules/auth/slice';
import appSlice from 'modules/app/slice';
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  auth: authSlice,
  app: appSlice
});

export default reducers;
