import { Button, Stack, Typography } from '@mui/material';

type Props = {
  errorMessage?: string;
  onReset?: () => void;
};

const SomethingWrong = ({ errorMessage, onReset }: Props) => {
  return (
    <Stack
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      padding={0.5}
      sx={{ backgroundColor: '#f5f5f5', borderRadius: 1, m: 1 }}
    >
      <Typography>Ops! Có gì đó sai sai.</Typography>
      {errorMessage && <Typography>{errorMessage}</Typography>}
      <Typography fontWeight={600}>Tải lại hoặc Báo Dev liền nha.</Typography>
      {onReset && <Button onClick={onReset}>Thử lại</Button>}
    </Stack>
  );
};

export default SomethingWrong;
