import { ETimekeepingBadRequestErrorCode } from 'types/response-code/timekeeping-code';

export const timekeepingErrorMessageInfo: Record<ETimekeepingBadRequestErrorCode, string> = {
  '100': 'Không tìm thấy nhân viên',
  '200': 'Không tìm thấy phiếu nghỉ phép này',
  '300': 'Không tìm thấy luật này',
  '400': 'Không tìm thấy phiếu này',
  '401': 'Phiếu này đã đóng',
  '500': 'Không tìm thấy sai phạm',
  '600': 'Không tìm thấy phiếu này',
  '601': 'Phiếu này đã đóng',
  '700': 'Không tìm thấy phiếu này',
  '-1': 'Vui lòng kiểm tra lại thông tin'
};
