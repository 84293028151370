import { RootState, useSelector } from 'store';

// ==============================|| AUTH HOOKS ||============================== //

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { isLogin, user, userRoleActions } = useSelector((state: RootState) => state.auth);

  return { isLogin, user, userRoleActions };
};
