import { EException } from 'types/response-code/inventory';

export const inventoryErrorMessageInfo: Record<EException, string> = {
  '-1': 'Vui lòng kiểm tra lại thông tin',
  '101': 'Phiếu kiểm kê không tìm thấy',
  '102': 'Phiếu kiểm kê đã đóng',
  '103': 'Đền bù không tìm thấy',
  '104': 'Nhân viên không tìm thấy',
  '105': 'Nhân viên đã được thêm đền bù trong phiếu này'
};
