import { useEffect } from 'react';

// types
// import { GuardProps } from 'types/auth';
import useQueryObject from 'hooks/useQueryObject';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router';
import ErrorRender from 'components/ErrorRender';
import { withErrorBoundary } from 'react-error-boundary';
import { KEYS } from 'constants/key';

const GuestGuard = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();

  const query = useQueryObject([KEYS.app.redirectAfterLogin, 'is_outside_app']);
  const isLogin = useAuth().isLogin;

  useEffect(() => {
    if (isLogin) {
      const redirect_url = (query[KEYS.app.redirectAfterLogin] || '') as string;
      if (query.is_outside_app == 'true' && redirect_url) {
        window.location.href = redirect_url;
      } else {
        navigate(redirect_url);
      }
    }
  }, [isLogin]);

  return children;
};
export default withErrorBoundary(GuestGuard, {
  FallbackComponent: ErrorRender
});
