import { FallbackProps } from 'react-error-boundary';
import SomethingWrong from './SomethingWrong';

const ErrorRender: React.ComponentType<FallbackProps> = ({ error, resetErrorBoundary: onReset }) => {
  return (
    // <Stack
    //   direction={'column'}
    //   alignItems={'center'}
    //   justifyContent={'center'}
    //   width={'100%'}
    //   padding={0.5}
    //   sx={{ backgroundColor: '#f5f5f5', borderRadius: 1, m: 1 }}
    // >
    //   <Typography>Ops! Có gì đó sai sai.</Typography>
    //   <Typography fontWeight={600}>Tải lại hoặc Báo Dev liền nha.</Typography>
    //   {onReset && <Button onClick={onReset}>Thử lại</Button>}
    // </Stack>
    <SomethingWrong />
  );
};

export default ErrorRender;

// // eslint-disable-next-line import/no-anonymous-default-export
// export default ({ onReset, errorMessage }: { onReset?: () => void; errorMessage?: string }) => {
//   return (

//   );
// };
